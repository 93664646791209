<div class="item-container">
    <span class="item-semaphore-left" ngClass="{{'item-semaphore-' + data?.status}}"></span>
    <div class="item-semaphore-center">
        <div>
            <span class="fw-semibold">[{{data?.item.itemId}}] {{data?.item.itemName}}</span>
        </div>
        <div *ngIf="data?.status === 0">
            {{data?.message}}
        </div>
        <div *ngIf="data?.status !== 0">
            Valor actual de medición: <span class="badge-neutral">{{data?.currentValue}}</span>
            Valor mímo referencial: <span class="badge-neutral">{{data?.minValue}}</span>
            Valor máximo referencial: <span class="badge-neutral">{{data?.maxValue}}</span>
        </div>
    </div>
    <span class="item-semaphore-right">
        <button matTooltip="Abrir gráfica en una nueva pestaña" *ngIf="data?.status === 2" mat-icon-button
            class="btn-clean-auto" (click)="onClickOpenTab(data?.item.uid)">
            <mat-icon class="fs-1">open_in_new</mat-icon>
        </button>
    </span>
</div>