import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-remove-telegram',
  templateUrl: './remove-telegram.component.html',
  styleUrls: ['./remove-telegram.component.scss']
})
export class RemoveTelegramComponent implements OnInit {

  @Input() user: any = {};

  constructor() { }

  ngOnInit(): void {
  }

  onClickRemove(): void{
    
  }

}
