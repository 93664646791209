import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ui-filter-category-items',
  templateUrl: './ui-filter-category-items.component.html',
  styleUrls: ['./ui-filter-category-items.component.scss']
})
export class UiFilterCategoryItemsComponent implements OnInit {

  @Input() form: FormGroup = null;

  @Input() solution: any = null;

  loading: any = {
    items: false
  };

  items: any = {
    latency: [],
    connectivity: [],
    performance: []
  };

  filteredLatency: Observable<any[]>;
  filteredConnectivity: Observable<any[]>;
  filteredPerformance: Observable<any[]>;

  constructor(private global: GlobalService, private api: ApiService) { }

  ngOnInit(): void {
    console.log('itemmsss');
  }

  set frmLatency(val) {
    this.form.get('latency').setValue(val);
  }
  get frmLatency() {
    return this.form.get('latency').value;
  }

  set frmConnectivity(val) {
    this.form.get('connectivity').setValue(val);
  }
  get frmConnectivity() {
    return this.form.get('connectivity').value;
  }

  set frmPerformance(val) {
    this.form.get('performance').setValue(val);
  }
  get frmPerformance() {
    return this.form.get('performance').value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.solution.currentValue !== null && changes.solution.currentValue !== -1) {
      this.frmLatency = '';
      this.frmConnectivity = '';
      this.frmPerformance = '';
      this.listItems(changes.solution.currentValue);
    } else {
      this.frmLatency = '';
      this.frmConnectivity = '';
      this.frmPerformance = '';
      this.filteredLatency = of([]);
      this.filteredConnectivity = of([]);
      this.filteredPerformance = of([]);
    }
  }

  displayFn(item: any): string {
    return item && item.itemName ? item.itemName : '';
  }

  private _filter(name: string, type: string): any[] {
    const filterValue = name.toLowerCase();
    if (type === 'latency') {
      return this.items.latency.filter(option => option.itemName.toLowerCase().includes(filterValue));
    } else if (type === 'connectivity') {
      return this.items.connectivity.filter(option => option.itemName.toLowerCase().includes(filterValue));
    } else if (type === 'performance') {
      return this.items.performance.filter(option => option.itemName.toLowerCase().includes(filterValue));
    } else {
      return [];
    }
  }

  listItems(solution: number): void {
    this.loading.items = true;
    const search = {
      solution
    };
    this.api.getItemsByCategory(search).subscribe((data: any) => {
      this.loading.items = false;
      if (this.global.is200(data)) {
        this.items = data.body;

        this.filteredLatency = this.form.get('latency').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.itemName)),
          map(name => (name ? this._filter(name, 'latency') : this.items.latency.slice())),
        );

        this.filteredConnectivity = this.form.get('connectivity').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.itemName)),
          map(name => (name ? this._filter(name, 'connectivity') : this.items.connectivity.slice())),
        );

        this.filteredPerformance = this.form.get('performance').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.itemName)),
          map(name => (name ? this._filter(name, 'performance') : this.items.performance.slice())),
        );

      }
    }, (data: any) => {
      this.global.handleError(data);
    });
  }

}
