import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../_services/common.service';

@Pipe({
  name: 'typeCiu'
})
export class TypeCiuPipe implements PipeTransform {

  typeCiuList: any = [];

  constructor(private common: CommonService){
    this.typeCiuList = this.common.getArray(this.common.getTypeCiuList())
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    let resp = null;
    for (const type of this.typeCiuList){
      if (type.code === value){
        resp = type.key;
        break;
      }
    }
    return resp;
  }

}
