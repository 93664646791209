import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-zgraphic',
  templateUrl: './zgraphic.component.html',
  styleUrls: ['./zgraphic.component.scss']
})
export class ZgraphicComponent implements OnInit {


  loading: any = {
    graphics: false
  };

  graphics: any = [];

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private bottomSheetRef: MatBottomSheetRef<ZgraphicComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      search: null
    });

    this.listGraphics();
  }


  listGraphics(): void {
    this.loading.graphics = true;
    this.graphics = [];
    this.api.getGraphicsByHost(this.data.server, this.data.host).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.graphics = false;
        this.graphics = data.body.result;
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onClickSelected(event: any, graphic: any): void {
    event.preventDefault();
    this.bottomSheetRef.dismiss(graphic);
  }

}
