<form *ngIf="form" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">

        <div fxFlex="40%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Cliente
                    <app-mini-loading *ngIf="loading.customers"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="customer" (selectionChange)="onChangeCustomer($event)">
                    <mat-option *ngFor="let c of customers" [value]="c.id">{{c.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="40%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>{{description.establishment.singular}}
                    <app-mini-loading *ngIf="loading.establishments"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="establishment" (selectionChange)="onChangeEstablishment($event)">
                    <mat-option *ngFor="let e of establishments" [value]="e.id">{{e.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</form>