import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
@Component({
  selector: 'app-view-graphic',
  templateUrl: './view-graphic.component.html',
  styleUrls: ['./view-graphic.component.scss']
})
export class ViewGraphicComponent implements OnInit {

  @Input() graphic: any;

  url = null;

  graphUrl = null;

  START_DATE: string = 'now-1h';

  END_DATE: string = 'now';

  timer: any;

  miliseconds: number = 15000;

  @ViewChild('imgRef') imgRef: ElementRef;

  constructor(private api: ApiService) {
    this.url = this.api.getBaseUrl();
  }

  ngOnInit(): void {

    this.graphUrl = this.generateGraphUrl();

    this.timer = this.startInterval();
  }


  startInterval(): any {
    return setInterval(() => {
      this.graphUrl = this.generateGraphUrl();
    }, this.miliseconds);
  }

  stopInterval(): void {
    clearInterval(this.timer);
    this.timer = null;
  }

  ngOnDestroy() {
    this.stopInterval();
  }


  generateGraphUrl(): string {
    return this.url + 'graphic?graphid=' + this.graphic.graphId + '&from=' + this.START_DATE + '&to=' + this.END_DATE + '&height=341&width=1656&profileIdx=web.charts.filter&refresh=' + new Date().getTime();
  }



}
