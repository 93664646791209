<form autocomplete="off" novalidate [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">
        <div fxFlex="40%" fxFlex.lt-md="100%">
            <mat-form-field appearance="outline">
                <mat-label>Departamento</mat-label>
                <mat-select formControlName="departament" (selectionChange)="onSelectDepartament($event.value)">
                    <mat-option *ngFor="let dep of departaments" [value]="dep.code">
                        {{dep.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.lt-md="100%">
            <mat-form-field appearance="outline">
                <mat-label>Provincia</mat-label>
                <mat-select formControlName="province" (selectionChange)="onSelectProvince($event.value)">
                    <mat-option *ngFor="let prov of provinces" [value]="prov.code">
                        {{prov.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.lt-md="100%">
            <mat-form-field appearance="outline">
                <mat-label>Distrito</mat-label>
                <mat-select formControlName="district" (selectionChange)="onSelectDistrict($event.value)">
                    <mat-option *ngFor="let dis of districts" [value]="dis.code">
                        {{dis.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>