<div class="mr-10 mt-20" *ngIf="graphic">
    <mat-card class="card-modal">
        <mat-card-content>

            <form autocomplete="off" novalidate [formGroup]="form">

                <div fxLayout="row" *ngIf="title">
                    <div fxFlex="100%" align="middle" class="title-graph">
                        {{graphic.graphName}}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="end">

                    <mat-form-field appearance="outline" style="width: 150px;">
                        <mat-label>Rango de tiempo</mat-label>
                        <mat-select formControlName="from" (selectionChange)="onSelectFilterFrom($event.value)">
                            <mat-option *ngFor="let time of times" [value]="time.value">
                                {{time.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="frmFrom && frmFrom === 'RANGE'">
                        <div class="container-range-date">
                            <!--start date-->
                            <div>
                                <mat-form-field appearance="outline" style="width: 150px;margin-left: 10px;">
                                    <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde"
                                        formControlName="dateFrom" (dateChange)="onChangeDateFrom($event)" required>
                                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #pickerFrom [showSeconds]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <!--end date-->
                            <div>
                                <mat-form-field appearance="outline" style="width: 150px;margin-left: 10px;">
                                    <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta"
                                        formControlName="dateTo" (dateChange)="onChangeDateTo($event)" required>
                                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #pickerTo [showSeconds]="true"> </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>

                    <button *ngIf="open" mat-icon-button (click)="onClickOpenTab(graphic.uid)"
                        style="margin: 6px;height: 45px;">
                        <mat-icon>open_in_new</mat-icon>
                    </button>

                </div>
            </form>

            <ng-container *ngIf="frmFrom && frmFrom === 'RANGE'">

                <app-no-data [big]="false" description="No ha especificado un rango de fechas"
                    *ngIf="!frmDateFrom || !frmDateTo"></app-no-data>

                <ng-container *ngIf="frmDateFrom && frmDateTo">
                    <div class="img-container">
                        <pinch-zoom *ngIf="graphUrl" [backgroundColor]="transparent">
                            <img #imgRef [src]="graphUrl" crossorigin="anonymous" style="border-radius: 5px;">
                        </pinch-zoom>
                    </div>
                </ng-container>

            </ng-container>

            <ng-container *ngIf="frmFrom && frmFrom !== 'RANGE'">
                <div class="img-container">
                    <pinch-zoom *ngIf="graphUrl" [backgroundColor]="transparent">
                        <img #imgRef [src]="graphUrl" crossorigin="anonymous" style="border-radius: 5px;">
                    </pinch-zoom>
                </div>
            </ng-container>


        </mat-card-content>
    </mat-card>
</div>