<app-loading-circular *ngIf="loading.graphics"></app-loading-circular>

<ng-container *ngIf="!loading.graphics">

    <app-no-data *ngIf="graphics.length == 0"></app-no-data>

    <ng-container *ngIf="graphics.length > 0">

        <form autocomplete="off" novalidate [formGroup]="form">
            <div fxLayout="row wrap">
                <div fxFlex="100%">
                    <mat-form-field appearance="outline">
                        <mat-label>Filtrar</mat-label>
                        <input matInput autocomplete="false" formControlName="search">
                    </mat-form-field>
                </div>
            </div>
        </form>


        <ng-container *ngIf="(graphics | filtername:form.get('search').value) as result">

            <div fxLayout="row wrap" *ngIf="result.length == 0">
                <div fxFlex="100%">
                    <app-no-data [big]="false" description="No se encontraron resultados">
                    </app-no-data>
                </div>
            </div>

            <!--<mat-nav-list>
                <a href="" *ngFor="let graphic of result" mat-list-item (click)="onClickSelected($event, graphic)">
                    <span mat-line>{{graphic.name}}</span>
                </a>
            </mat-nav-list>-->

            <div fxLayout="row wrap">
                <div fxFlex="25%" fxFlex.lt-md="33.33%" fxFlex.xs="50%" *ngFor="let graphic of result">
                    <div class="p-10">
                        <div matRipple style="cursor: pointer;" (click)="onClickSelected($event, graphic)">
                            <mat-card class="card-modal">
                                <mat-card-content class="text-center">
                                    <div>
                                        <img src="./assets/graphic.png" alt="{{graphic.name}}">
                                    </div>
                                    <p>
                                        {{graphic.name}}
                                    </p>
                                    <p>
                                        <span class="badge-neutral">[graphid] {{graphic.graphid}}</span>
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


    </ng-container>

</ng-container>