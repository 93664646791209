import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterItemName'
})
export class FilterItemNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if(args[0] === null){
      return value;
    }else{
      return value.filter(item => {
        return item.itemName.toLowerCase().includes(args[0].toLowerCase());
      });
    }
  }

}
