import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ui-upload',
  templateUrl: './ui-upload.component.html',
  styleUrls: ['./ui-upload.component.scss']
})
export class UiUploadComponent implements OnInit {

  title: string;
  afuConfig: any;

  constructor(private api: ApiService, private global: GlobalService,
              private dialogRef: MatDialogRef<UiUploadComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
  }

  ngOnInit(): void {

    this.afuConfig = {
      multiple: false,
      formatsAllowed: this.data.extensions,
      maxSize: '10',
      uploadAPI: {
        url: this.data.url,
        method: 'POST',
        params: this.data.row
      },
      hideResetBtn: false,
      replaceTexts: {
        selectFileBtn: 'Seleccionar archivo',
        sizeLimit: 'Tamaño máximo',
        uploadBtn: 'Subir archivo',
        resetBtn: 'Reiniciar'
      }
    };

  }

  onClickCancel(): void{
    this.dialogRef.close();
  }

  onResponseUpload(event: any): void{
    if (this.global.is200(event)) {
      const resp = event.body;
      this.global.showMessage(this.global.getMessage(event));
      setTimeout(() => {
        this.dialogRef.close(true);
      }, 1000);
    }
  }

}
