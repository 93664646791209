import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-graphic',
  templateUrl: './btn-graphic.component.html',
  styleUrls: ['./btn-graphic.component.scss']
})
export class BtnGraphicComponent implements OnInit {

  @Input() uid: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  onClickOpenTab(): void {
    window.open(window.location.origin + '/graphic/' + this.uid, '_blank');
  }

}
