import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ui-filter-customer',
  templateUrl: './ui-filter-customer.component.html',
  styleUrls: ['./ui-filter-customer.component.scss']
})
export class UiFilterCustomerComponent implements OnInit {

  @Input() form: FormGroup = null;

  @Output() onEstablishmentSelected = new EventEmitter();

  loading: any = {
    customers: false,
    establishments: false
  };

  customers: any = [];

  establishments: any = [];

  description: any = environment.description;

  constructor(private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {
    this.listCustomers();
  }

  set frmCustomer(val) {
    this.form.get('customer').setValue(val);
  }
  get frmCustomer() {
    return this.form.get('customer').value;
  }

  set frmEstablishment(val) {
    this.form.get('establishment').setValue(val);
  }
  get frmEstablishment() {
    return this.form.get('establishment').value;
  }

  generateChoice(): any{
    return {
      id: -1,
      name: 'Seleccione'
    };
  }

  listCustomers(): void {
    this.loading.customers = true;
    this.api.getCustomersForSelect().subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.customers = false;
        const body = data.body.data;
        if(body.all){
          this.customers = [this.generateChoice()];
          this.customers = this.customers.concat(body.list);
        }else{
          this.customers = body.list;
          this.form.get('customer').disable();
        }
        this.frmCustomer = this.customers[0].id;
        if(this.frmCustomer !== -1){
          this.listEstablishments(this.frmCustomer);
        }
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onChangeCustomer(event: any): void {
    if (event.value !== -1) {
      this.listEstablishments(event.value);
    } else {
      this.frmEstablishment = null;
      this.establishments = [];
    }
  }

  listEstablishments(id: number): void {
    this.loading.establishments = true;
    this.api.getEstablishmentsForSelect(id).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.establishments = false;
        const body = data.body.data;
        if(body.all){
          this.establishments = [this.generateChoice()];
          this.establishments = this.establishments.concat(body.list);
        }else{
          this.establishments = body.list;
          this.form.get('establishment').disable();
        }
        this.frmEstablishment = this.establishments[0].id;
        this.onEstablishmentSelected.emit(this.frmEstablishment);
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });

  }

  onChangeEstablishment(event: any): void {
    this.onEstablishmentSelected.emit(event.value);
  }

}
