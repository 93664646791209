import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';
@Component({
  selector: 'app-view-full-graphic',
  templateUrl: './view-full-graphic.component.html',
  styleUrls: ['./view-full-graphic.component.scss']
})
export class ViewFullGraphicComponent implements OnInit {

  @Input() graphic: any;

  @Input() open: boolean = true;

  @Input() title: boolean = true;

  url = null;

  graphUrl = null;

  times: any = [
    {
      name: 'Hace 1 hora',
      value: 'now-1h'
    },
    {
      name: 'Hace 7 horas',
      value: 'now-7h'
    },
    {
      name: 'Últimas 24 horas',
      value: 'now-24h'
    },
    {
      name: 'Hace 7 días',
      value: 'now-7d'
    },
    {
      name: 'Hace 30 días',
      value: 'now-30d'
    },
    {
      name: 'Hace 2 meses',
      value: 'now-2M'
    },
    {
      name: 'Hace 6 meses',
      value: 'now-6M'
    },
    {
      name: 'Hace 12 meses',
      value: 'now-1y'
    },
    {
      name: 'Rango de fechas',
      value: 'RANGE'
    }
  ];

  START_DATE: string = '';

  END_DATE: string = 'now';

  form: FormGroup;

  timer: any;

  miliseconds: number = 15000;

  @ViewChild('imgRef') imgRef: ElementRef;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private datepipe: DatePipe, private global: GlobalService) {
    this.url = this.api.getBaseUrl();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      from: this.times[0].value,
      dateFrom: [null, [Validators.required]],
      dateTo: [null, [Validators.required]]
    });

    this.START_DATE = this.frmFrom;

    this.graphUrl = this.generateGraphUrl();

    this.timer = this.startInterval();
  }

  set frmFrom(val) {
    this.form.get('from').setValue(val);
  }
  get frmFrom() {
    return this.form.get('from').value;
  }

  set frmDateFrom(val) {
    this.form.get('dateFrom').setValue(val);
  }
  get frmDateFrom() {
    return this.form.get('dateFrom').value;
  }

  set frmDateTo(val) {
    this.form.get('dateTo').setValue(val);
  }
  get frmDateTo() {
    return this.form.get('dateTo').value;
  }

  startInterval(): any {
    return setInterval(() => {
      this.graphUrl = this.generateGraphUrl();
    }, this.miliseconds);
  }

  stopInterval(): void {
    clearInterval(this.timer);
    this.timer = null;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  onSelectFilterFrom(value: any): void {
    this.stopInterval();
    if (value === 'RANGE') {
      this.graphUrl = null;
    } else {
      this.START_DATE = value;
      this.END_DATE = 'now';
      this.preloadGraphUrl();
      if (!this.timer) {
        this.timer = this.startInterval();
      }
    }
  }

  preloadGraphUrl(): void {
    this.graphUrl = null;
    setTimeout(() => {
      this.graphUrl = this.generateGraphUrl();
    }, 10);
  }


  generateGraphUrl(): string {
    const width = window.innerWidth;
    return this.url + 'graphic?graphid=' + this.graphic.graphId + '&from=' + this.START_DATE + '&to=' + this.END_DATE + '&height=341&width='+width+'&profileIdx=web.charts.filter&refresh=' + new Date().getTime();
  }


  onChangeDateFrom(event: any): void {
    if (event.value) {
      if (this.frmDateTo) {
        this.generateRangeDates();
      } else {
        this.global.showMessage('Ingrese una fecha final válida');
      }
    } else {
      this.global.showMessage('Ingrese una fecha de inicio válida');
    }
  }

  onChangeDateTo(event: any): void {
    if (event.value) {
      if (this.frmDateFrom) {
        this.generateRangeDates();
      } else {
        this.global.showMessage('Ingrese una fecha de inicio válida');
      }
    } else {
      this.global.showMessage('Ingrese una fecha final válida');
    }
  }

  generateRangeDates(): void {
    this.END_DATE = this.datepipe.transform(this.frmDateTo, 'yyyy-MM-dd HH:mm:ss');
    this.START_DATE = this.datepipe.transform(this.frmDateFrom, 'yyyy-MM-dd HH:mm:ss');
    this.preloadGraphUrl();
  }

  onClickOpenTab(uid: string): void{
    window.open(window.location.origin + '/graphic/' + uid, '_blank');
  }

}
