
<ng-container [formGroup]="form">
	<mat-form-field appearance="outline">
		<mat-label>{{label}} {{isrequired ? '*':''}}</mat-label>
		<input matInput formControlName="search" placeholder="Buscar" aria-label="Buscar"
			[matAutocomplete]="auto" matTooltip="{{tooltip}}" (input)="onChange($event)">
		<mat-icon matSuffix>search</mat-icon>
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
			(optionSelected)="optionSelected($event)">

			<mat-option *ngIf="autcompleteLoading" class="is-loading">Obteniendo registros...
			</mat-option>

			<ng-container *ngIf="!autcompleteLoading">
				<mat-option *ngFor="let item of results; let index = index" [value]="item">
					{{item.strFormat}}
				</mat-option>
			</ng-container>

		</mat-autocomplete>
	</mat-form-field>
</ng-container>