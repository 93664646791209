import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterGraphName'
})
export class FilterGraphNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if(typeof args[0] === 'undefined' || args[0] === null){
      return value;
    }else{
      return value.filter(item => {
        return item.graphName.toLowerCase().includes(args[0].toLowerCase());
      });
    }
  }

}
