import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ubigeo',
  templateUrl: './ubigeo.component.html',
  styleUrls: ['./ubigeo.component.scss']
})
export class UbigeoComponent implements OnInit {

  form: FormGroup;
  departaments: any = [];
  provinces: any = [];
  districts: any = [];

  dep: string = null;
  pro: string = null;
  dis: string = null;

  @Input() ubigeo: string;
  @Output() onSelected = new EventEmitter();
  @Output() onChanged = new EventEmitter();

  constructor(private api: ApiService, private global: GlobalService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      departament: null,
      province: null,
      district: null
    });

    this.emptyDepartaments();
    this.emptyProvinces();
    this.emptyDistricts();

    if (this.ubigeo && this.ubigeo !== null && this.ubigeo.length === 6) {
      this.dep = this.ubigeo.substring(0, 2);
      this.pro = this.ubigeo.substring(2, 4);
      this.dis = this.ubigeo.substring(4, 6);
    }

    this.getRegions();
  }

  emptyDepartaments() {
    this.departaments = this.generateSelect('Seleccione');
    this.form.get('departament').setValue(this.departaments[0].code);
  }

  emptyProvinces() {
    this.provinces = this.generateSelect('Seleccione');
    this.form.get('province').setValue(this.provinces[0].code);
  }

  emptyDistricts() {
    this.districts = this.generateSelect('Seleccione');
    this.form.get('district').setValue(this.districts[0].code);
  }

  generateSelect(texto: string) {
    const lista: any = [];
    lista.push({
      code: '00',
      name: texto
    });
    return lista;
  }

  getRegions() {
    this.departaments = this.generateSelect('Cargando...');
    this.form.get('departament').setValue(this.departaments[0].code);
    this.api.getRegions().subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.departaments = this.generateSelect('Seleccione');
        const resp = data.body;
        for (let i = 0; i < resp.length; i++) {
          this.departaments.push({
            code: resp[i].code,
            name: resp[i].name
          });
        }
        if (this.dep !== null) {
          this.form.get('departament').setValue(this.dep);
          this.getProvinces(this.form.get('departament').value);
        } else {
          this.form.get('departament').setValue(this.departaments[0].code);
        }
      }
    }, err => this.global.handleError(err, false));
  }

  getProvinces(region: string) {
    this.provinces = this.generateSelect('Cargando...');
    this.form.get('province').setValue(this.provinces[0].code);
    this.api.getProvinces(region).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.provinces = this.generateSelect('Seleccione');
        const resp = data.body;
        for (let i = 0; i < resp.length; i++) {
          this.provinces.push({
            code: resp[i].code,
            name: resp[i].name
          });
        }
        if (this.pro !== null) {
          this.form.get('province').setValue(this.pro);
          this.getDistricts(this.form.get('departament').value, this.form.get('province').value);
        } else {
          this.form.get('province').setValue(this.provinces[0].code);
        }
      }
    }, err => this.global.handleError(err, false));
  }

  getDistricts(region: string, province: string) {
    this.districts = this.generateSelect('Cargando...');
    this.form.get('district').setValue(this.districts[0].code);
    this.api.getDistricts(region, province).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.districts = this.generateSelect('Seleccione');
        const resp = data.body;
        for (let i = 0; i < resp.length; i++) {
          this.districts.push({
            code: resp[i].code,
            name: resp[i].name
          });
        }
        if (this.dis !== null) {
          this.form.get('district').setValue(this.dis);
        } else {
          this.form.get('district').setValue(this.districts[0].code);
        }
      }
    }, err => this.global.handleError(err, false));
  }

  onSelectDepartament(value: any) {
    this.emptyDistricts();
    this.getProvinces(value);
    this.onSelected.emit(null);
    if (value !== '00') {
      this.onChanged.emit(value);
    } else {
      this.onChanged.emit(null);
    }
  }

  onSelectProvince(value: any) {
    this.getDistricts(this.form.get('departament').value, value);
    this.onSelected.emit(null);
    if (value !== '00') {
      this.onChanged.emit(this.form.get('departament').value + value);
    } else {
      this.onChanged.emit(this.form.get('departament').value);
    }
  }

  onSelectDistrict(value: any) {
    if (value !== '00') {
      const ubigeo = this.form.get('departament').value + this.form.get('province').value + this.form.get('district').value;
      this.onSelected.emit(ubigeo);
      this.onChanged.emit(ubigeo);
    } else {
      this.onSelected.emit(null);
      this.onChanged.emit(this.form.get('departament').value + this.form.get('province').value);
    }
  }
}
