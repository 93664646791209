import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  isCancel: boolean;
  message: string;

  constructor(private dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.isCancel = this.data.isCancel || false;
    this.message = this.data.message || '';
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickAccept(): void{
    this.dialogRef.close(true);
  }

}
