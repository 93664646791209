<form *ngIf="form" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">
        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Latencia
                    <app-count [count]="(filteredLatency | async)?.length"></app-count>
                    <app-mini-loading *ngIf="loading.items"></app-mini-loading>
                </mat-label>
                <input type="text" matInput formControlName="latency" [matAutocomplete]="autolatency">
                <mat-autocomplete #autolatency="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredLatency | async" [value]="option">
                        {{option.itemName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Packet Loss
                    <app-count [count]="(filteredConnectivity | async)?.length"></app-count>
                    <app-mini-loading *ngIf="loading.items"></app-mini-loading>
                </mat-label>
                <input type="text" matInput formControlName="connectivity" [matAutocomplete]="autoconnectivity">
                <mat-autocomplete #autoconnectivity="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredConnectivity | async" [value]="option">
                        {{option.itemName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxFlex="40%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Velocidad
                    <app-count [count]="(filteredPerformance | async)?.length"></app-count>
                    <app-mini-loading *ngIf="loading.items"></app-mini-loading>
                </mat-label>
                <input type="text" matInput formControlName="performance" [matAutocomplete]="autoperformance">
                <mat-autocomplete #autoperformance="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredPerformance | async" [value]="option">
                        {{option.itemName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</form>