import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { delay } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MatToolbar } from '@angular/material/toolbar';
import { GlobalService } from './_services/global.service';
import { ConfirmComponent } from './_components/confirm/confirm.component';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './_services/api.service';
import { environment } from 'src/environments/environment';
import { CommonService } from './_services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @ViewChild(MatToolbar) toolbar: MatToolbar;

  resizeObservable$: Observable<Event>;

  resizeSubscription$: Subscription;

  contentHeight: number = window.innerHeight;

  IS_AUTHENTICATED: boolean = false;

  isAuthenticated$: Subscription = null;

  sidebarListener$: Subscription = null;

  IS_RESPONSIVE: boolean = false;

  active: any;

  IS_DARK_MODE: boolean = false;

  modules: any = [];
  routes: any = [];

  moduleSelected: any = null;

  loadingRoutes: boolean = true;

  private eventSource: any = null;

  constructor(private observer: BreakpointObserver, private global: GlobalService, private router: Router, private api: ApiService, private ngZone: NgZone, private commons: CommonService) {
    this.IS_AUTHENTICATED = this.global.isAuthenticated();
  }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.active = val.url.substring(1, val.url.length);
        this.getRoutes();
      }
    });
    this.resize();
    this.IS_DARK_MODE = this.global.getIsDarkMode();
    this.darkModeGenerate();
    this.calculateHeight(window.innerHeight);

    if (this.IS_AUTHENTICATED) {
      
      this.eventSource = new EventSource(environment.mercure + 'NOTIFICATIONS');

      this.eventSource.onmessage = (event: any) => {
        const data = JSON.parse(event.data);

        //console.log('mercure generic>>> ', data);

        const decoded: any = this.global.getDecodeToken();

        //console.log('decoded>>> ', decoded);

        if (decoded && decoded.id === data.uid) {

          if (data.type === 'REPORT_READY') {
            this.global.showAlert('Tiene un reporte de calidad listo.', 'Ver reportes', (result) => {
              if (result.isConfirmed) {
                this.ngZone.run(() => {
                  if (this.router.url === '/reports/generated_reports') {
                    window.location.reload();
                  } else {
                    this.router.navigateByUrl('/reports/generated_reports', { replaceUrl: true });
                  }

                });
              }
            });
          }
        }

        //if (decoded && decoded.listenTickets) {
          if (data.type === 'LISTEN_TICKETS') {
            console.log(data.ticket);
            if (data.ticket.event_nstatus === this.commons.getStatusTicketList().RESOLVED.code) {
              this.ngZone.run(() => {
                this.global.toastSuccess('RESUELTO', data.ticket.event_name);
              });
            } else {
              this.ngZone.run(() => {
                this.global.toastError('PROBLEMA', data.ticket.event_name);
              });
            }
          }
        //}

      };

    }
  }

  getRoutes(): void {
    if (this.active === 'login') {
      this.routes = [];
    } else {
      /*if (this.global.isTokenExpired()) {
        this.global.removeToken();
      }else{
        
      }*/
      if (this.routes.length === 0) {
        //call routes
        this.loadingRoutes = true;
        this.api.getMenu().subscribe((data: any) => {
          if (this.global.is200(data)) {
            setTimeout(() => {
              this.loadingRoutes = false;
              this.modules = data.body;
              if(this.modules.length > 0) this.selectedModule(this.modules[0]);
              
            }, 50);
          }
        }, (error: any) => {
          this.global.handleError(error, false);
        });
        //end call routes
      }
    }
  }


  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 1280px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        this.IS_RESPONSIVE = res.matches;
        this.sidenavHandler();
      });

    this.isAuthenticated$ = this.global.getIsAuthenticated$().subscribe((isAuth) => {
      this.IS_AUTHENTICATED = isAuth;
      if (this.IS_AUTHENTICATED) {
        setTimeout(() => {
          this.sidenavHandler();
        }, 100);
      }
    });

    this.sidebarListener$ = this.global.getSidebarListener$().subscribe((isAuth) => {
      setTimeout(() => {
        this.sidenav.close();
      }, 0);
    });


  }

  ngOnDestroy(): void {
    if (this.isAuthenticated$) {
      this.isAuthenticated$.unsubscribe();
    }
    if (this.sidebarListener$) {
      this.sidebarListener$.unsubscribe();
    }
  }

  resize(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscription$ = this.resizeObservable$.subscribe(event => {
      const w = event.target as Window;
      this.calculateHeight(w.innerHeight);
    });
  }

  calculateHeight(height: any): void {
    setTimeout(() => {
      const header = this.toolbar ? this.toolbar._elementRef.nativeElement.offsetHeight : 0;
      this.contentHeight = height - header;
    }, 10);
  }

  sidenavHandler(): void {
    if (this.sidenav) {
      if (this.IS_RESPONSIVE) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    }
  }

  onClickMyAccount(): void {
    this.router.navigateByUrl('/account');
  }

  onClickCloseSession(): void {
    const data: any = {
      width: '300px',
      isCancel: true,
      message: 'Seguro que quiere cerrar sesión?'
    };
    this.global.openModal(data, ConfirmComponent, (response: any) => {
      if (response) {
        this.global.removeToken();
        this.router.navigateByUrl('/login', { replaceUrl: true });
      }
    });
  }

  onClickOpen(event: any, item: any): void {
    //console.log(event, item);
    event.preventDefault();
    //this.sidenav.toggle();
    this.router.navigateByUrl('/' + item.alias);
  }

  onClickDarkMode(): void {
    this.IS_DARK_MODE = !this.IS_DARK_MODE;
    this.darkModeGenerate();
  }

  darkModeGenerate(): void {
    if (this.IS_DARK_MODE) {
      this.global.setDarkMode();
      document.body.classList.add('dark-mode');
    } else {
      this.global.setLightMode();
      document.body.classList.remove('dark-mode');
    }
  }

  selectedModule(module: any): void{
    this.moduleSelected = module;
    this.routes = this.moduleSelected.sections;
    //this.router.navigateByUrl('/');
  }

  onClickShowModule(module: any): void{
    this.selectedModule(module);
  }

}
