import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url: string;
  api: string;

  constructor(private http: HttpClient) {
    this.url = environment.protocol + environment.url + '/';
    this.api = environment.protocol + environment.url + '/api/';
  }

  getBaseUrl(): string {
    return this.url;
  }

  getApiUrl(): string {
    return this.api;
  }

  getLogin(data: any): any {
    return this.http.post(this.api + 'login_check', data, { observe: 'response' });
  }

  /*
  * SEARCH
  */

  search(path: string, data: any): Observable<any> {
    return this.http.post<any>(this.api + 'autocomplete/' + path, data, { observe: 'response' })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }
  /*
  * ROUTES
  */
  getMenu(): any {
    return this.http.get(this.api + 'menu', { observe: 'response' });
  }
  getSections(id: number) {
    return this.http.get(this.api + 'modules/' + id + '/sections', { observe: 'response' });
  }
  addSection(data: any, id: number) {
    return this.http.post(this.api + 'modules/' + id + '/sections', data, { observe: 'response' });
  }
  updateSection(id: number, data: any) {
    return this.http.put(this.api + 'sections/' + id, data, { observe: 'response' });
  }
  deleteSection(id: number) {
    return this.http.delete(this.api + 'sections/' + id, { observe: 'response' });
  }
  getMenus(id: number) {
    return this.http.get(this.api + 'sections/' + id + '/routes', { observe: 'response' });
  }
  addRoute(data: any, id: number) {
    return this.http.post(this.api + 'sections/' + id + '/routes', data, { observe: 'response' });
  }
  updateRoute(id: number, data: any) {
    return this.http.put(this.api + 'routes/' + id, data, { observe: 'response' });
  }
  deleteRoute(id: number) {
    return this.http.delete(this.api + 'routes/' + id, { observe: 'response' });
  }
  getOperations(id: number) {
    return this.http.get(this.api + 'route/' + id + '/operations', { observe: 'response' });
  }
  addOperation(data: any, id: number) {
    return this.http.post(this.api + 'route/' + id + '/operations', data, { observe: 'response' });
  }
  updateOperation(id: number, data: any) {
    return this.http.put(this.api + 'operations/' + id, data, { observe: 'response' });
  }
  deleteOperation(id: number) {
    return this.http.delete(this.api + 'operations/' + id, { observe: 'response' });
  }
  getVerify(alias: string, config: boolean): any {
    return this.http.post(this.api + 'route/verify', { alias, config }, { observe: 'response' });
  }
  getModules() {
    return this.http.get(this.api + 'modules', { observe: 'response' });
  }
  addModule(data: any) {
    return this.http.post(this.api + 'modules', data, { observe: 'response' });
  }
  updateModule(id: number, data: any) {
    return this.http.put(this.api + 'modules/' + id, data, { observe: 'response' });
  }
  deleteModule(id: number) {
    return this.http.delete(this.api + 'modules/' + id, { observe: 'response' });
  }
  /*
  * END ROUTES
  */

  /*
 * CUSTOMERS
 */
  searchCustomers(data: any): any {
    return this.http.post(this.api + 'customer/search', data, { observe: 'response' });
  }
  addCustomer(data: any) {
    return this.http.post(this.api + 'customer', data, { observe: 'response' });
  }
  updateCustomer(id: number, data: any) {
    return this.http.put(this.api + 'customer/' + id, data, { observe: 'response' });
  }
  deleteCustomer(id: number) {
    return this.http.delete(this.api + 'customer/' + id, { observe: 'response' });
  }
  reactivateCustomer(id: number) {
    return this.http.get(this.api + 'customer/' + id + '/reactivate', { observe: 'response' });
  }
  resetPasswordCustomer(data: any, id: number) {
    return this.http.post(this.api + 'customer/' + id + '/reset', data, { observe: 'response' });
  }
  getEstablishments(id: number) {
    return this.http.get(this.api + 'customer/' + id + '/establishments', { observe: 'response' });
  }
  addEstablishment(data: any, id: number) {
    return this.http.post(this.api + 'customer/' + id + '/establishments', data, { observe: 'response' });
  }
  updateEstablishment(id: number, data: any) {
    return this.http.put(this.api + 'establishments/' + id, data, { observe: 'response' });
  }
  deleteEstablishment(id: number) {
    return this.http.delete(this.api + 'establishments/' + id, { observe: 'response' });
  }
  resetPasswordEstablishment(data: any, id: number) {
    return this.http.post(this.api + 'establishments/' + id + '/reset', data, { observe: 'response' });
  }
  /*
  * END ROUTES
  */

  /*
  * UBIGEOS
  */
  getRegions() {
    return this.http.get(this.api + 'ubigeo/regions', { observe: 'response' });
  }
  getProvinces(region: string) {
    return this.http.get(this.api + 'ubigeo/region/' + region + '/provinces', { observe: 'response' });
  }
  getDistricts(region: string, province: string) {
    return this.http.get(this.api + 'ubigeo/region/' + region + '/province/' + province + '/districts', { observe: 'response' });
  }
  /*
  * END UBIGEOS
  */

  /*
 * PRODUCTS
 */
  searchProducts(data: any): any {
    return this.http.post(this.api + 'products/search', data, { observe: 'response' });
  }
  addProduct(data: any) {
    return this.http.post(this.api + 'products', data, { observe: 'response' });
  }
  updateProduct(id: number, data: any) {
    return this.http.put(this.api + 'products/' + id, data, { observe: 'response' });
  }
  deleteProduct(id: number) {
    return this.http.delete(this.api + 'products/' + id, { observe: 'response' });
  }
  assignSpeedsToProduct(product: any, data: any) {
    return this.http.post(this.api + 'products/' + product + '/assign/speeds', data, { observe: 'response' });
  }
  getProductsAssignSpeeds() {
    return this.http.get(this.api + 'products/speeds', { observe: 'response' });
  }
  /*
    * END PRODUCTS
    */

  /*
* ROLES
*/
  getRoles() {
    return this.http.get(this.api + 'roles', { observe: 'response' });
  }
  getAdminRoles() {
    return this.http.get(this.api + 'roles/admin', { observe: 'response' });
  }
  getEstablishmentRoles() {
    return this.http.get(this.api + 'roles/establishment', { observe: 'response' });
  }
  addRol(data: any) {
    return this.http.post(this.api + 'roles', data, { observe: 'response' });
  }
  updateRol(id: number, data: any) {
    return this.http.put(this.api + 'roles/' + id, data, { observe: 'response' });
  }
  deleteRol(id: number) {
    return this.http.delete(this.api + 'roles/' + id, { observe: 'response' });
  }
  configureRol(data: any) {
    return this.http.post(this.api + 'roles/configure', data, { observe: 'response' });
  }
  /*
    * END ROLES
    */
  /*
 * PERMISSIONS
 */
  getPermissions(rol: number) {
    return this.http.get(this.api + 'roles/' + rol + '/permissions', { observe: 'response' });
  }
  assignRoutePermissions(rol: any, data: any) {
    return this.http.post(this.api + 'roles/' + rol + '/permissions/routes', data, { observe: 'response' });
  }
  assignOperationPermissions(rol: any, data: any) {
    return this.http.post(this.api + 'roles/' + rol + '/permissions/operations', data, { observe: 'response' });
  }

  /*updateRol(id: number, data: any) {
    return this.http.put(this.api + 'roles/' + id, data, { observe: 'response' });
  }*/
  /*
    * END ROLES
    */

  /*
* TICKETS
*/
  searchTickets(data: any): any {
    return this.http.post(this.api + 'tickets/search', data, { observe: 'response' });
  }

  /*
    * END TICKETS
    */

  /*
* USERS
*/
  getUsers() {
    return this.http.get(this.api + 'users', { observe: 'response' });
  }
  addUser(data: any) {
    return this.http.post(this.api + 'users', data, { observe: 'response' });
  }
  updateUser(id: number, data: any) {
    return this.http.put(this.api + 'users/' + id, data, { observe: 'response' });
  }
  deleteUser(id: number) {
    return this.http.delete(this.api + 'users/' + id, { observe: 'response' });
  }
  resetPasswordUser(data: any, id: any) {
    return this.http.post(this.api + 'users/' + id + '/reset', data, { observe: 'response' });
  }
  /*
     * END USERS
     */


  /*
* SERVERS
*/
  getServers() {
    return this.http.get(this.api + 'servers', { observe: 'response' });
  }
  addServer(data: any) {
    return this.http.post(this.api + 'servers', data, { observe: 'response' });
  }
  updateServer(id: number, data: any) {
    return this.http.put(this.api + 'servers/' + id, data, { observe: 'response' });
  }
  deleteServer(id: number) {
    return this.http.delete(this.api + 'servers/' + id, { observe: 'response' });
  }
  versionServer(id: number) {
    return this.http.get(this.api + 'servers/version/' + id, { observe: 'response' });
  }
  connectServer(id: number) {
    return this.http.get(this.api + 'servers/connect/' + id, { observe: 'response' });
  }
  testServer(id: number, data: any) {
    return this.http.post(this.api + 'servers/test/' + id, data, { observe: 'response' });
  }
  getHostsServer(id: number) {
    return this.http.get(this.api + 'servers/' + id + '/hosts', { observe: 'response' });
  }
  getGraphicsByHost(id: number, host: any) {
    return this.http.get(this.api + 'servers/' + id + '/hosts/' + host + '/graphics', { observe: 'response' });
  }
  getItemsByHost(id: number, host: any) {
    return this.http.get(this.api + 'servers/' + id + '/hosts/' + host + '/items', { observe: 'response' });
  }
  getGraphicsServer(id: number, host: any) {
    return this.http.get(this.api + 'servers/' + id + '/hosts/' + host + '/graphics', { observe: 'response' });
  }
  addGraphicServer(data: any) {
    return this.http.post(this.api + 'servers/graphics', data, { observe: 'response' });
  }
  addItemServer(data: any) {
    return this.http.post(this.api + 'servers/items', data, { observe: 'response' });
  }
  configureItemServer(data: any) {
    return this.http.post(this.api + 'servers/items/configure', data, { observe: 'response' });
  }
  updateItemServer(data: any) {
    return this.http.post(this.api + 'servers/items/update', data, { observe: 'response' });
  }
  deleteItemServer(id: number) {
    return this.http.delete(this.api + 'servers/items/' + id, { observe: 'response' });
  }
  deleteGraphicServer(id: number) {
    return this.http.delete(this.api + 'servers/graphics/' + id, { observe: 'response' });
  }
  syncItem(id: number) {
    return this.http.delete(this.api + 'items/' + id + '/sync', { observe: 'response' });
  }
  syncGraphic(id: number) {
    return this.http.delete(this.api + 'graphics/' + id + '/sync', { observe: 'response' });
  }
  getItemsByGraphic(id: number) {
    return this.http.get(this.api + 'graphics/' + id + '/items', { observe: 'response' });
  }
  addItemsByGraphic(id: number, data: any) {
    return this.http.post(this.api + 'graphics/' + id + '/items', data, { observe: 'response' });
  }
  deleteItemsByGraphic(id: number) {
    return this.http.delete(this.api + 'graphics/items/assignments/' + id, { observe: 'response' });
  }
  /*
     * END USERS
     */


  /*
* SPEEDS
*/

  /*
     * END USERS
     */

  /*
* SERVICES  getEstablishmentProducts(id: number) {
    return this.http.get(this.api + 'establishments/' + id + '/products', { observe: 'response' });
  }
*/
  getServices(id: number) {
    return this.http.get(this.api + 'services/establishment/' + id, { observe: 'response' });
  }
  addService(data: any) {
    return this.http.post(this.api + 'services', data, { observe: 'response' });
  }
  updateService(id: number, data: any) {
    return this.http.put(this.api + 'services/' + id, data, { observe: 'response' });
  }
  deleteService(id: number) {
    return this.http.delete(this.api + 'services/' + id, { observe: 'response' });
  }
  getGraphics(data: any) {
    return this.http.post(this.api + 'services/graphics/search', data, { observe: 'response' });
  }
  getItems(data: any) {
    return this.http.post(this.api + 'services/items/search', data, { observe: 'response' });
  }
  getItemsByCategory(data: any) {
    return this.http.post(this.api + 'services/items/search/category', data, { observe: 'response' });
  }
  getItemsByCategorySolution(data: any) {
    return this.http.post(this.api + 'services/items/search/category/solution', data, { observe: 'response' });
  }
  getGraphicsByCategorySolution(data: any) {
    return this.http.post(this.api + 'services/graphics/search/category/solution', data, { observe: 'response' });
  }
  getSuperCategoryAndCategoriesBySolution(data: any) {
    return this.http.post(this.api + 'services/items/search/supercategory/solution', data, { observe: 'response' });
  }
  /*
     * END USERS
     */


  /**
   * Soluciones
   */
  searchSolutions(data: any): any {
    return this.http.post(this.api + 'solutions/search', data, { observe: 'response' });
  }

  getListSolutions() {
    return this.http.get(this.api + 'solutions/list', { observe: 'response' });
  }

  getEstablishmentsInSolutions() {
    return this.http.get(this.api + 'solutions/establishments', { observe: 'response' });
  }


  /*REPORTS */
  getListSemaphore(data: any): any {
    return this.http.post(this.api + 'reports/semaphore', data, { observe: 'response' });
  }

  getReportQuality(data: any): any {
    return this.http.post(this.api + 'reports/quality', data, { observe: 'response' });
  }

  getGraphicsSummary() {
    return this.http.get(this.api + 'reports/graphics-summary', { observe: 'response' });
  }

  getGraphicsSummaryDetail(uid: string) {
    return this.http.get(this.api + 'reports/graphics-summary/' + uid, { observe: 'response' });
  }

  getGraphicDetail(uid: string) {
    return this.http.get(this.api + 'reports/graphic/' + uid, { observe: 'response' });
  }

  getDownloadReportQuality(data: any): any {
    return this.http.post(this.api + 'reports/quality/download', data, { observe: 'response' });
  }

  getReportsQualityFiles(id: String): any {
    return this.http.get(this.api + 'reports/quality/' + id + '/files', { observe: 'response' });
  }

  getGeneratedReports(data: any): any {
    return this.http.post(this.api + 'reports/generated', data, { observe: 'response' });
  }

  getReportDownloadFile(reportUid: string, fileUid: string): any {
    return this.http.get(this.api + 'reports/' + reportUid + '/files/' + fileUid + '/download', { responseType: 'blob' });
  }

  getReportDownloadAllFiles(reportUid: string): any {
    return this.http.get(this.api + 'reports/' + reportUid + '/download', { responseType: 'blob' });
  }

  /*
* QUALITY POLITICS
*/
  getQualityPolicies() {
    return this.http.get(this.api + 'quality-policies', { observe: 'response' });
  }
  addQualityPolitics(data: any) {
    return this.http.post(this.api + 'quality-policies', data, { observe: 'response' });
  }
  updateQualityPolitics(id: number, data: any) {
    return this.http.put(this.api + 'quality-policies/' + id, data, { observe: 'response' });
  }
  deleteQualityPolitics(id: number) {
    return this.http.delete(this.api + 'quality-policies/' + id, { observe: 'response' });
  }
  getItemLinkedToQualityPolitics(id: number) {
    return this.http.get(this.api + 'quality-policies/' + id + '/item', { observe: 'response' });
  }
  addItemQualityPolitics(data: any) {
    return this.http.post(this.api + 'quality-policies/item', data, { observe: 'response' });
  }

  /*
  * MEASUREMENT CATEGORIES
  */
  getMeasurementCategories() {
    return this.http.get(this.api + 'measurement-categories', { observe: 'response' });
  }
  addMeasurementCategories(data: any) {
    return this.http.post(this.api + 'measurement-categories', data, { observe: 'response' });
  }
  updateMeasurementCategories(id: number, data: any) {
    return this.http.put(this.api + 'measurement-categories/' + id, data, { observe: 'response' });
  }
  deleteMeasurementCategories(id: number) {
    return this.http.delete(this.api + 'measurement-categories/' + id, { observe: 'response' });
  }


  /*
* MEASUREMENT SUPER CATEGORIES
*/
  getSuperCategories() {
    return this.http.get(this.api + 'super-categories', { observe: 'response' });
  }
  addSuperCategory(data: any) {
    return this.http.post(this.api + 'super-categories', data, { observe: 'response' });
  }
  updateSuperCategory(id: number, data: any) {
    return this.http.put(this.api + 'super-categories/' + id, data, { observe: 'response' });
  }
  deleteSuperCategory(id: number) {
    return this.http.delete(this.api + 'super-categories/' + id, { observe: 'response' });
  }
  getMeasurementCategoriesBySuperCategory(id: number) {
    return this.http.get(this.api + 'super-categories/' + id + '/measurement-categories', { observe: 'response' });
  }
  /**ITEMS */


  searchItemsAndQualityPolicies(data: any) {
    return this.http.post(this.api + 'items/quality-policies', data, { observe: 'response' });
  }

  /*
 * ACCOUNT
 */
  getAccount() {
    return this.http.get(this.api + 'account', { observe: 'response' });
  }
  updateAccount(id: number, data: any) {
    return this.http.put(this.api + 'account/' + id, data, { observe: 'response' });
  }

  /**
   * DATA FOR SELECTS
   */
  getCustomersForSelect(): any {
    return this.http.get(this.api + 'select/customers', { observe: 'response' });
  }

  getEstablishmentsForSelect(id: number) {
    return this.http.get(this.api + 'select/customer/' + id + '/establishments', { observe: 'response' });
  }

  getSolutionsForSelect(id: number) {
    return this.http.get(this.api + 'select/establishment/' + id + '/solutions', { observe: 'response' });
  }

}
