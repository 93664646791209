import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-circular',
  templateUrl: './loading-circular.component.html',
  styleUrls: ['./loading-circular.component.scss']
})
export class LoadingCircularComponent implements OnInit {

  @Input() big: number = 1;
  @Input() msg: string = 'Espere, estamos cargando los datos...';

  constructor() { }

  ngOnInit(): void {
  }

}
