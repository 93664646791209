import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { ApiService } from 'src/app/_services/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, startWith, catchError, debounceTime, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  autcompleteLoading: boolean;

  @Input() form: FormGroup = null;

  results: any = [];

  @Output() selected = new EventEmitter<any>();

  @Input() tooltip: string;

  @Input() label: string;

  @Input() uri: string;

  @Input() isclear: boolean = false;

  @Input() isrequired: boolean = false;

  @Input() data: any = {};

  constructor(private formBuilder: FormBuilder, private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {

    this.form.get('search').valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(500),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '' && typeof value === 'string' && value.length > 2) {
          this.autcompleteLoading = true;
          this.results = [];
          return this.lookup(value);
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    ).subscribe(data => {
      if (data) {
        if (data.error) {
          this.autcompleteLoading = false;
          this.global.showError('Intente otra vez.');
        } else {
          this.autcompleteLoading = false;
          this.results = data;
        }
      } else {
        this.results = [];
      }
    });

  }

  displayFn(option: any): string {
    return option ? option.strName : '';
  }

  lookup(value: string): Observable<any> {
    return this.api.search(this.uri, {
      query: value,
      page: 0,
      rows: 100,
      data: this.data
    }).pipe(
      // map the item property of the github results as our return object
      map(results => results.content ?? results ),
      // catch errors
      catchError(_ => {
        return of({ error: true });
      })
    );
  }

  optionSelected(event: any): void {
    this.selected.emit(this.form.get('search').value);
    if (this.isclear) {
      this.form.get('search').setValue(null);
    }
  }

  onChange(event: any): void {
    this.selected.emit(null);
  }


}
