<form *ngIf="form" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">
        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Super Categoría
                    <app-mini-loading *ngIf="loading.superCategories"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="superCategory" (selectionChange)="onChangeSuperCategory($event)">
                    <mat-option *ngFor="let su of superCategories" [value]="su.id">
                        {{su.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Categoría de medición
                    <app-mini-loading *ngIf="loading.measurement"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="measurement" (selectionChange)="onChangeMeasurement($event)">
                    <mat-option *ngFor="let t of measurements" [value]="t.id">
                        {{t.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="40%" fxFlex.lt-md="100%" fxFlex.xs="100%" *ngIf="showItems === 'ITEM' || showItems === 'GRAPHIC'">
            <mat-form-field appearance="outline">
                <mat-label>{{showItems === 'ITEM' ? 'Item de medición':'Gráficas de medición'}} <app-count [count]="(filteredItems | async)?.length"></app-count>
                    <app-mini-loading *ngIf="loading.items"></app-mini-loading>
                </mat-label>
                <input type="text" matInput formControlName="item" [matAutocomplete]="autoitem">
                <mat-autocomplete #autoitem="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredItems | async" [value]="option">
                        {{showItems === 'ITEM' ? option.itemName : option.graphName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</form>