<app-loading-circular *ngIf="loading.items"></app-loading-circular>

<ng-container *ngIf="!loading.items">

    <app-no-data *ngIf="items.length == 0"></app-no-data>

    <ng-container *ngIf="items.length > 0">

        <form autocomplete="off" novalidate [formGroup]="form">
            <div fxLayout="row wrap">
                <div fxFlex="100%">
                    <mat-form-field appearance="outline">
                        <mat-label>Filtrar</mat-label>
                        <input matInput autocomplete="false" formControlName="search">
                    </mat-form-field>
                </div>
            </div>
        </form>

        <ng-container *ngIf="(items | filtername:form.get('search').value) as result">

            <div fxLayout="row wrap" *ngIf="result.length == 0">
                <div fxFlex="100%">
                    <app-no-data [big]="false" description="No se encontraron resultados">
                    </app-no-data>
                </div>
            </div>

            <mat-nav-list>
                <a href="" *ngFor="let item of result" mat-list-item (click)="onClickSelected($event, item)">
                    <span mat-line>{{item.name}}</span>
                </a>
            </mat-nav-list>

        </ng-container>



    </ng-container>

</ng-container>