<form *ngIf="form" [formGroup]="form">

    <div fxLayout="row wrap" fxLayoutGap="1rem grid">
        <div fxFlex="60%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Categorías de medición</mat-label>
                <mat-select formControlName="measurement" multiple (selectionChange)="onSelectCategory($event.value)">
                    <mat-optgroup *ngFor="let group of categoriesGroups" [label]="group.name">
                        <mat-option color="accent" *ngFor="let measurement of group.categories" [value]="measurement.id">
                            {{measurement.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

</form>