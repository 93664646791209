<ng-container *ngIf="icon">
    <div class="mt-50 mb-50">
        <div class="text-center">
            <img *ngIf="big" src="{{info ? 'assets/info.svg' : 'assets/empty.svg'}}" alt="{{description}}" width="200px">
            <img *ngIf="!big" src="{{info ? 'assets/info.svg' : 'assets/empty.svg'}}" alt="{{description}}" width="60px">
        </div>
        <h3 *ngIf="big" class="text-center fw-light">
            {{description}}
        </h3>
        <h4 *ngIf="!big" class="text-center fw-light">
            {{description}}
        </h4>
    </div>
</ng-container>

<ng-container *ngIf="!icon">
    <div class="mt-10 mb-10">
        <p class="text-center fw-light">{{description}}</p>
    </div>
</ng-container>