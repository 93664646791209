<mat-card class="card-modal mt-20" *ngIf="graphic">
    <mat-card-content>

        <h5 class="text-center">{{graphic.graphName}}</h5>
        
        <div class="img-container">
            <img #imgRef *ngIf="graphUrl" [src]="graphUrl" crossorigin="anonymous">
        </div>


    </mat-card-content>
</mat-card>