<ng-container>
    <div *ngIf="!render" class="text-center fs-0-75">
        Generando semáforo...
    </div>
    <div #doughnut style="width: 70%;margin: 0 auto;"></div>
</ng-container>

<ng-container *ngIf="render">
    <div class="mb-10 text-center">
        <span class="fs-0-75 mr-10">
            <span class="semaphore-legend-error"></span> +2 categorías fuera de rango
        </span>
        <span class="fs-0-75 mr-10">
            <span class="semaphore-legend-ambar"></span> 1 categoría fuera de rango
        </span>
        <span class="fs-0-75 mr-10">
            <span class="semaphore-legend-ok"></span> Categorías correctas
        </span>
    </div>
</ng-container>