import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-zitem',
  templateUrl: './zitem.component.html',
  styleUrls: ['./zitem.component.scss']
})
export class ZitemComponent implements OnInit {

 

  loading: any = {
    items: false
  };

  items: any = [];

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private bottomSheetRef: MatBottomSheetRef<ZitemComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
			search: null
		});

    this.listItems();
  }

  listItems(): void{
    this.loading.items = true;
    this.items = [];
    this.api.getItemsByHost(this.data.server, this.data.host).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.items = false;
        this.items = data.body.result;
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onClickSelected(event: any, item: any): void {
    event.preventDefault();
    this.bottomSheetRef.dismiss(item);
  }


}
