import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './confirm/confirm.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoadingSidebarComponent } from './loading-sidebar/loading-sidebar.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NoDataComponent } from './no-data/no-data.component';
import { UbigeoComponent } from './ubigeo/ubigeo.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingCircularComponent } from './loading-circular/loading-circular.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UnverifiedComponent } from './unverified/unverified.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MiniLoadingComponent } from './mini-loading/mini-loading.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonPipesModule } from '../_pipes/common-pipes.module';
import { ViewGraphicComponent } from './view-graphic/view-graphic.component';
import { ViewFullGraphicComponent } from './view-full-graphic/view-full-graphic.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { SemaphoreCategoryComponent } from './semaphore-category/semaphore-category.component';
import { UiFilterCustomerSolutionComponent } from './ui-filter-customer-solution/ui-filter-customer-solution.component';
import { UiFilterCategoryComponent } from './ui-filter-category/ui-filter-category.component';
import { CountComponent } from './count/count.component';
import { RemoveTelegramComponent } from './remove-telegram/remove-telegram.component';
import { ItemSemaphoreComponent } from './item-semaphore/item-semaphore.component';
import { UiFilterDateComponent } from './ui-filter-date/ui-filter-date.component';
import { UiFilterCategoryGroupComponent } from './ui-filter-category-group/ui-filter-category-group.component';
import { UiFilterCategoryItemsComponent } from './ui-filter-category-items/ui-filter-category-items.component';
import { LegendItemsSemaphoreComponent } from './legend-items-semaphore/legend-items-semaphore.component';
import { SimpleSemaphoreComponent } from './simple-semaphore/simple-semaphore.component';
import { ZgraphicComponent } from './zgraphic/zgraphic.component';
import { ZitemComponent } from './zitem/zitem.component';
import { MatListModule } from '@angular/material/list';
import { BtnGraphicComponent } from './btn-graphic/btn-graphic.component';
import { UiFilterCustomerComponent } from './ui-filter-customer/ui-filter-customer.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { UiUploadComponent } from './ui-upload/ui-upload.component';

@NgModule({
  declarations: [
    ConfirmComponent,
    LoadingSidebarComponent,
    NotAuthorizedComponent,
    NoDataComponent,
    UbigeoComponent,
    LoadingCircularComponent,
    UnverifiedComponent,
    MiniLoadingComponent,
    ViewGraphicComponent,
    ViewFullGraphicComponent,
    AutocompleteComponent,
    SemaphoreCategoryComponent,
    UiFilterCustomerSolutionComponent,
    UiFilterCategoryComponent,
    CountComponent,
    RemoveTelegramComponent,
    ItemSemaphoreComponent,
    UiFilterDateComponent,
    UiFilterCategoryGroupComponent,
    UiFilterCategoryItemsComponent,
    LegendItemsSemaphoreComponent,
    SimpleSemaphoreComponent,
    ZgraphicComponent,
    ZitemComponent,
    BtnGraphicComponent,
    UiFilterCustomerComponent,
    UiUploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    MatInputModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    CommonPipesModule,
    MatTooltipModule,
    MatAutocompleteModule,
    PinchZoomModule,
    MatListModule,
    AngularFileUploaderModule
  ],
  exports: [
    LoadingSidebarComponent,
    NoDataComponent,
    UbigeoComponent,
    LoadingCircularComponent,
    UnverifiedComponent,
    MiniLoadingComponent,
    ViewGraphicComponent,
    ViewFullGraphicComponent,
    AutocompleteComponent,
    SemaphoreCategoryComponent,
    UiFilterCustomerSolutionComponent,
    UiFilterCategoryComponent,
    CountComponent,
    RemoveTelegramComponent,
    ItemSemaphoreComponent,
    UiFilterDateComponent,
    UiFilterCategoryGroupComponent,
    UiFilterCategoryItemsComponent,
    LegendItemsSemaphoreComponent,
    SimpleSemaphoreComponent,
    BtnGraphicComponent,
    UiFilterCustomerComponent,
  ]
})
export class ComponentsModule { }
