<div class="ph-container">
    <div class="ph-item ph-100"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-100"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-100"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
    <div class="ph-item ph-opacity ph-80"></div>
</div>