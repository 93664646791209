<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" *ngIf="IS_AUTHENTICATED">

        <app-loading-sidebar *ngIf="loadingRoutes"></app-loading-sidebar>

        <ng-container *ngIf="!loadingRoutes">

            <div class="center mt-10 mb-10">
                <a href="" routerLink="/"><img alt="" src="./assets/logo-white.png" style="width:200px;"></a>
            </div>

            <div class="sidebar" *ngFor="let section of routes">
                <span class="section">{{section.name}}</span>

                <mat-list role="list" *ngIf="section.routes.length > 0">
                    <a mat-list-item href="" *ngFor="let item of section.routes" (click)="onClickOpen($event, item)"
                        [ngClass]="{'selected': item.alias === active}">
                        {{item.name}}
                    </a>
                </mat-list>

            </div>
        </ng-container>



    </mat-sidenav>
    <mat-sidenav-content>

        <mat-toolbar #toolbar="matToolbar" *ngIf="IS_AUTHENTICATED">
            <button mat-icon-button (click)="sidenav.toggle()" class="mr-10">
                <mat-icon>
                    menu
                </mat-icon>
            </button>

            <button mat-stroked-button aria-label="account" [matMenuTriggerFor]="menuDashboard" matTooltip="Módulos disponibles">
                <mat-icon>space_dashboard</mat-icon> {{moduleSelected?.name}}
            </button>
            <mat-menu #menuDashboard="matMenu">
                <button mat-menu-item *ngFor="let module of modules" (click)="onClickShowModule(module)">
                    <mat-icon>{{module.icon}}</mat-icon>
                    <span>{{module.name}}</span>
                </button>
            </mat-menu>

            <span class="toolbar-spacer"></span>

            <button mat-icon-button aria-label="dark mode" (click)="onClickDarkMode()" matTooltip="{{IS_DARK_MODE ? 'Activar modo claro' : 'Activar modo oscuro'}}">
                <mat-icon *ngIf="!IS_DARK_MODE">dark_mode</mat-icon>
                <mat-icon *ngIf="IS_DARK_MODE">wb_sunny</mat-icon>
            </button>

            <button mat-icon-button aria-label="account" [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onClickMyAccount()">
                    <mat-icon>person_outline</mat-icon>
                    <span>Mi cuenta</span>
                </button>
                <button mat-menu-item (click)="onClickCloseSession()">
                    <mat-icon>not_interested</mat-icon>
                    <span>Cerrar sesi&oacute;n</span>
                </button>
            </mat-menu>

        </mat-toolbar>

        <div class="content" [ngClass]="{'content-login':!IS_AUTHENTICATED}" [style.height.px]="contentHeight">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>