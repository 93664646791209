import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './_auth/login.guard';
import { SecureGuard } from './_auth/secure.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./_pages/home/home.module').then(m => m.HomeModule), canActivate: [SecureGuard]
  },
  {
    path: 'customers', loadChildren: () => import('./_pages/customers/customers.module').then(m => m.CustomersModule), canActivate: [SecureGuard]
  },
  {
    path: 'products', loadChildren: () => import('./_pages/products/products.module').then(m => m.ProductsModule), canActivate: [SecureGuard]
  },
  {
    path: 'items', loadChildren: () => import('./_pages/items/items.module').then(m => m.ItemsModule), canActivate: [SecureGuard]
  },
  {
    path: 'routes', loadChildren: () => import('./_pages/routes/routes.module').then(m => m.RoutesModule), canActivate: [SecureGuard]
  },
  {
    path: 'roles', loadChildren: () => import('./_pages/roles/roles.module').then(m => m.RolesModule), canActivate: [SecureGuard]
  },
  {
    path: 'users', loadChildren: () => import('./_pages/users/users.module').then(m => m.UsersModule), canActivate: [SecureGuard]
  },
  {
    path: 'tickets', loadChildren: () => import('./_pages/tickets/tickets.module').then(m => m.TicketsModule), canActivate: [SecureGuard]
  },
  {
    path: 'servers', loadChildren: () => import('./_pages/servers/servers.module').then(m => m.ServersModule), canActivate: [SecureGuard]
  },
  {
    path: 'speeds', loadChildren: () => import('./_pages/speeds/speeds.module').then(m => m.SpeedsModule), canActivate: [SecureGuard]
  },
  {
    path: 'solutions', loadChildren: () => import('./_pages/solutions/solutions.module').then(m => m.SolutionsModule), canActivate: [SecureGuard]
  },
  {
    path: 'graphics', loadChildren: () => import('./_pages/graphics/graphics.module').then(m => m.GraphicsModule), canActivate: [SecureGuard]
  },
  {
    path: 'reports', loadChildren: () => import('./_pages/reports/reports.module').then(m => m.ReportsModule), canActivate: [SecureGuard]
  },
  {
    path: 'parameters', loadChildren: () => import('./_pages/parameters/parameters.module').then(m => m.ParametersModule), canActivate: [SecureGuard]
  },
  {
    path: 'quality-policies', loadChildren: () => import('./_pages/quality-policies/quality-policies.module').then(m => m.QualityPoliciesModule), canActivate: [SecureGuard]
  },
  {
    path: 'measurement-categories', loadChildren: () => import('./_pages/measurement-categories/measurement-categories.module').then(m => m.MeasurementCategoriesModule), canActivate: [SecureGuard]
  },
  {
    path: 'graphic', loadChildren: () => import('./_pages/open-graphic/open-graphic.module').then(m => m.OpenGraphicModule), canActivate: [SecureGuard]
  },
  {
    path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard]
  },
  {
    path: 'account', loadChildren: () => import('./_pages/account/account.module').then(m => m.AccountModule), canActivate: [SecureGuard]
  },
  //speed test
  {
    path: 'speed-test/tool', loadChildren: () => import('./_modules/speed-test/pages/tool/tool.module').then(m => m.ToolModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/servers', loadChildren: () => import('./_modules/speed-test/pages/servers/servers.module').then(m => m.ServersModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/ip-ranges', loadChildren: () => import('./_modules/speed-test/pages/ip-ranges/ip-ranges.module').then(m => m.IpRangesModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/indicators-policies', loadChildren: () => import('./_modules/speed-test/pages/indicators-policies/indicators-policies.module').then(m => m.IndicatorsPoliciesModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/reports/month-average', loadChildren: () => import('./_modules/speed-test/pages/reports/weekly-average/weekly-average.module').then(m => m.WeeklyAverageModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/reports/historical', loadChildren: () => import('./_modules/speed-test/pages/reports/historical/historical.module').then(m => m.HistoricalModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/reports/historical-by-ubigeo', loadChildren: () => import('./_modules/speed-test/pages/reports/historical-by-ubigeo/historical-by-ubigeo.module').then(m => m.HistoricalByUbigeoModule), canActivate: [SecureGuard]
  },
  {
    path: 'speed-test/reports/month-average-by-ubigeo', loadChildren: () => import('./_modules/speed-test/pages/reports/month-average-by-ubigeo/month-average-by-ubigeo.module').then(m => m.MonthAverageByUbigeoModule), canActivate: [SecureGuard]
  },
  //configuration
  {
    path: 'configuration/pool', loadChildren: () => import('./_modules/administration/pages/pool/pool.module').then(m => m.PoolModule), canActivate: [SecureGuard]
  },
  {
    path: 'configuration/api-keys', loadChildren: () => import('./_modules/administration/pages/configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: [SecureGuard]
  },
  {
    //path: '**', pathMatch: 'full', redirectTo: ''
    path: '**', loadChildren: () => import('./_pages/notfound/notfound.module').then(m => m.NotfoundModule), canActivate: [SecureGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
