<div class="mb-10 text-right">
    <span class="fs-0-75 mr-10">Leyenda para items:</span>
    <span class="fs-0-75 mr-10">
        <span class="item-semaphore-legend-0"></span> No definido
    </span>
    <span class="fs-0-75 mr-10">
        <span class="item-semaphore-legend-1"></span> Válido
    </span>
    <span class="fs-0-75 mr-10">
        <span class="item-semaphore-legend-2"></span> Inválido
    </span>
</div>