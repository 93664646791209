import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilternamePipe } from './filtername.pipe';
import { StatusTicketPipe } from './status-ticket.pipe';
import { TypeCiuPipe } from './type-ciu.pipe';
import { FilterGraphNamePipe } from './filter-graph-name.pipe';
import { FilterItemNamePipe } from './filter-item-name.pipe';
import { SeverityTicketPipe } from './severity-ticket.pipe';
import { PrettyPrintPipePipe } from './pretty-print-pipe.pipe';



@NgModule({
  declarations: [
    FilternamePipe,
    StatusTicketPipe,
    TypeCiuPipe,
    FilterGraphNamePipe,
    FilterItemNamePipe,
    SeverityTicketPipe,
    PrettyPrintPipePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilternamePipe,
    StatusTicketPipe,
    TypeCiuPipe,
    FilterGraphNamePipe,
    FilterItemNamePipe,
    SeverityTicketPipe,
    PrettyPrintPipePipe
  ]
})
export class CommonPipesModule { }
