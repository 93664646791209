import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../_services/common.service';

@Pipe({
  name: 'severityTicket'
})
export class SeverityTicketPipe implements PipeTransform {

  list: any = [];

  constructor(private common: CommonService){
    this.list = this.common.getArray(this.common.getSeverityList())
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    let resp = null;
    for (const type of this.list){
      if (type.code === value){
        resp = type.name;
        break;
      }
    }
    return resp;
  }
}
