import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-semaphore-category',
  templateUrl: './semaphore-category.component.html',
  styleUrls: ['./semaphore-category.component.scss']
})
export class SemaphoreCategoryComponent implements OnInit {

  private doughnut: ElementRef;

  render: boolean = false;

  @ViewChild('doughnut', { static: false }) set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.doughnut = content;
    }
  }

  @Input() items: any = {};

  RED: string = '#e74c3c';
  GREEN: string = '#43d71e';
  GRAY: string = 'lightgray';
  AMBAR: string = '#e67e22';

  constructor(private commons: CommonService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.items.currentValue) {
      //console.log('>>>>', changes.items.currentValue);
      this.generateGraphic();
    }

  }

  generateGraphic(color: string = 'transparent'): void {
    this.render = false;
    setTimeout(() => {

      var data = [{
        fill: 33,
        color: this.GRAY,
        title: "L A T E N C Y",
        id: 'latency',
        txt_x: '70',
        txt_y: '30',
        txt_rotate: '60'
      }, {
        fill: 34,
        color: this.GRAY,
        title: "C O N N E C T I V I T Y",
        id: 'connectivity',
        txt_x: '35',
        txt_y: '80',
        txt_rotate: '0'
      },
      {
        fill: 33,
        color: this.GRAY,
        title: "P E R F O R M A N C E",
        id: 'performance',
        txt_x: '20',
        txt_y: '50',
        txt_rotate: '-60'
      }
      ];

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      let filled = 0;
      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");
      svg.setAttribute("viewBox", "0 0 100 100");

      console.log('data', data);

      for (let i = 0; i < data.length; i++) {
        const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        const startAngle = -90;
        const radius = 30;
        const cx = 50;
        const cy = 50;
        const strokeWidth = 15;
        const dashArray = 2 * Math.PI * radius;
        const dashOffset = dashArray - (dashArray * data[i].fill / 100);
        const angle = (filled * 360 / 100) + startAngle;

        circle.setAttribute("r", radius.toString());
        circle.setAttribute("cx", cx.toString());
        circle.setAttribute("cy", cy.toString());
        circle.setAttribute("fill", "transparent");
        circle.setAttribute("stroke", data[i].color);
        circle.setAttribute("stroke-width", strokeWidth.toString());
        circle.setAttribute("stroke-dasharray", dashArray.toString());
        circle.setAttribute("stroke-dashoffset", dashOffset.toString());
        circle.setAttribute("transform", "rotate(" + (angle) + " " + cx + " " + cy + ")");
        circle.setAttribute("id", data[i].id);
        svg.appendChild(circle);

        const txt = document.createElementNS("http://www.w3.org/2000/svg", "text");
        txt.setAttribute("x", data[i].txt_x);
        txt.setAttribute("y", data[i].txt_y);
        txt.setAttribute("fill", "black");
        txt.setAttribute("font-size", "3");
        txt.setAttribute("transform", "rotate(" + (data[i].txt_rotate) + " " + data[i].txt_x + " " + data[i].txt_y + ")");
        txt.innerHTML = data[i].title;
        svg.appendChild(txt);
        filled += data[i].fill;
      }

      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      circle.setAttribute("r", "20");
      circle.setAttribute("cx", "50");
      circle.setAttribute("cy", "50");
      circle.setAttribute("stroke", "transparent");
      circle.setAttribute("fill", color);
      circle.setAttribute("id", "light");
      svg.appendChild(circle);

      this.doughnut.nativeElement.appendChild(svg);

      const light = document.getElementById('light');
      light.style.animation = 'opacityAnimation 0.75s infinite';
      const performance = document.getElementById('performance');
      const latency = document.getElementById('latency');
      const connectivity = document.getElementById('connectivity');

      let sum = 0;
      let exists = false;


      console.log(this.items);

      if (this.items && this.items.latency && this.items.connectivity && this.items.performance) {


        const latencyError = this.items.latency.filter((item: any) => {
          return item.status === 2;
        });

        if (latencyError.length > 0) {
          latency.style.stroke = this.RED;
          sum++;
        } else {
          const latencyNone = this.items.latency.filter((item: any) => {
            return item.status === 0;
          });
          if(latencyNone.length === this.items.latency.length){
            latency.style.stroke = this.GRAY;
          }else{
            latency.style.stroke = this.GREEN;
          }
        }

        const connectivityError = this.items.connectivity.filter((item: any) => {
          return item.status === 2;
        });

        if (connectivityError.length > 0) {
          connectivity.style.stroke = this.RED;
          sum++;
        } else {
          const connectivityNone = this.items.connectivity.filter((item: any) => {
            return item.status === 0;
          });
          if(connectivityNone.length === this.items.connectivity.length){
            connectivity.style.stroke = this.GRAY;
          }else{
            connectivity.style.stroke = this.GREEN;
          }
        }

        const performanceError = this.items.performance.filter((item: any) => {
          return item.status === 2;
        });

        if (performanceError.length > 0) {
          performance.style.stroke = this.RED;
          sum++;
        } else {
          const performanceNone = this.items.performance.filter((item: any) => {
            return item.status === 0;
          });
          if(performanceNone.length === this.items.performance.length){
            performance.style.stroke = this.GRAY;
          }else{
            performance.style.stroke = this.GREEN;
          }
        }

        if (sum >= 2) {
          light.style.fill = this.RED;
        } else if (sum === 1) {
          light.style.fill = this.AMBAR;
        } else {
          light.style.fill = this.GREEN;
          /*if (exists) {
            light.style.fill = this.GREEN;
          } else {
            light.style.fill = this.GRAY;
          }*/
        }


      }

      this.render = true;

    }, 1000);

  }



}
