import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-simple-semaphore',
  templateUrl: './simple-semaphore.component.html',
  styleUrls: ['./simple-semaphore.component.scss']
})
export class SimpleSemaphoreComponent implements OnInit {

  @Input() items: any = [];

  private doughnut: ElementRef;

  render: boolean = false;

  @ViewChild('doughnut', { static: false }) set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.doughnut = content;
    }
  }

  RED: string = '#e74c3c';
  GREEN: string = '#43d71e';
  GRAY: string = 'lightgray';
  AMBAR: string = '#e67e22';

  constructor(private common: CommonService) { }

  ngOnInit(): void {
 
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.items.currentValue) {
      this.generateGraphic();
    }

  }

  generateGraphic(color: string = 'red'): void {
    this.render = false;
    setTimeout(() => {
      this.render = true;

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      let filled = 0;
      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");
      svg.setAttribute("viewBox", "0 0 100 100");

      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      circle.setAttribute("r", "20");
      circle.setAttribute("cx", "50");
      circle.setAttribute("cy", "50");
      circle.setAttribute("stroke", "transparent");
      circle.setAttribute("fill", color);
      circle.setAttribute("id", "light");
      svg.appendChild(circle);

      this.doughnut.nativeElement.appendChild(svg);

      const light = document.getElementById('light');
      

      let sum = 0;
      let countNone = 0;
      for(let i = 0; i< this.items.length; i++){
        if(this.items[i].status === this.common.statusMeasurementCategory.VALUE_ERROR){
          sum++;
        }
        if(this.items[i].status === this.common.statusMeasurementCategory.VALUE_NONE){
          countNone++;
        }
      }

      if (sum >= 2) {
        light.style.fill = this.RED;
        light.style.animation = 'opacityAnimation 0.75s infinite';
      } else if (sum === 1) {
        light.style.fill = this.AMBAR;
        light.style.animation = 'opacityAnimation 0.75s infinite';
      } else {
        if (countNone === this.items.length) {
          light.style.fill = this.GRAY;
        } else {
          light.style.fill = this.GREEN;
          light.style.animation = 'opacityAnimation 0.75s infinite';
        }
      }

      
    }, 1000);
  }


}
