<form *ngIf="form" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">

        <div fxFlex="25%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Rango de tiempo</mat-label>
                <mat-select formControlName="from" (selectionChange)="onSelectFilterFrom($event.value)">
                    <mat-option *ngFor="let time of times" [value]="time.value">
                        {{time.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="frmFrom && frmFrom === 'RANGE'">

            <div fxFlex="25%" fxFlex.lt-md="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde" formControlName="dateFrom"
                        (dateChange)="onChangeDateFrom($event)" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerFrom [showSeconds]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div fxFlex="25%" fxFlex.lt-md="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta" formControlName="dateTo"
                        (dateChange)="onChangeDateTo($event)" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerTo [showSeconds]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

        </ng-container>

        <div fxFlex="25%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>Intervalor promedio</mat-label>
                <mat-select formControlName="interval" (selectionChange)="onSelectFilterInterval($event.value)">
                    <mat-option *ngFor="let inter of intervals" [value]="inter.value">
                        {{inter.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</form>