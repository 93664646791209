import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-semaphore',
  templateUrl: './item-semaphore.component.html',
  styleUrls: ['./item-semaphore.component.scss']
})
export class ItemSemaphoreComponent implements OnInit {

  @Input() data: any = null;

  constructor() { }

  ngOnInit(): void {
  }

  onClickOpenTab(uid: string): void {
    window.open(window.location.origin + '/graphic/' + uid, '_blank');
  }

}
