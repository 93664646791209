import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { NotAuthorizedComponent } from '../_components/not-authorized/not-authorized.component';
import { ApiService } from './api.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  isAuthenticated$: Subject<any>;

  sidebarListener$: Subject<any>;

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private jwtHelper: JwtHelperService, private api: ApiService, private bottomSheet: MatBottomSheet, private toastr: ToastrService) {
    this.isAuthenticated$ = new Subject();
    this.sidebarListener$ = new Subject();
  }

  getIsDarkMode(): boolean{
    const dark = localStorage.getItem('dark');
    return dark && dark === 'true';
  }

  setDarkMode(): void{
    localStorage.setItem('dark', 'true');
  }

  setLightMode(): void{
    localStorage.setItem('dark', 'false');
  }

  isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired();
  }

  getDecodeToken(): boolean {
    return this.jwtHelper.decodeToken(localStorage.getItem('token'));
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  saveToken(token: string): void{
    localStorage.setItem('token', token);
    this.isAuthenticated$.next(true);
  }

  removeToken(): void {
    localStorage.removeItem('token');
    this.isAuthenticated$.next(false);
  }

  getIsAuthenticated$(): Observable<any> {
    return this.isAuthenticated$.asObservable();
  }

  getSidebarListener$(): Observable<any> {
    return this.sidebarListener$.asObservable();
  }

  showLoading(message: string): void {
    let html = '';
    html += '<div class="loading" style="position:fixed;top:0;left:0;width:100%;height:100%;background: url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDJGMDA5QUNFRTI1MTFFQUI5RTlFQzMxOUQxMUU4MDYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDJGMDA5QURFRTI1MTFFQUI5RTlFQzMxOUQxMUU4MDYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MkYwMDlBQUVFMjUxMUVBQjlFOUVDMzE5RDExRTgwNiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MkYwMDlBQkVFMjUxMUVBQjlFOUVDMzE5RDExRTgwNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuZe5/EAAAAPSURBVHjaYmBhYt4MEGAAANQAvVjRDWIAAAAASUVORK5CYII=\') repeat;z-index: 999999;">';
    html += '<div style="position: relative;width:100%;height: 100%;">';
    // tslint:disable-next-line:max-line-length
    html += '<img src="assets/loading.svg" width="80" alt="Cargando..." style="position: absolute;top:50%;left:50%;margin-left:-40px;">';
    if (typeof message !== 'undefined') {
      html += '<p style="width:200px;position: absolute;top:50%;left:50%;margin-left:-100px;color:#fff;margin-top: 50px;text-align: center;font-size: 0.75rem;">';
      html += message;
      html += '</p>';
    }
    html += '</div>';
    html += '</div>';

    const placeholder = document.createElement('div');
    placeholder.innerHTML = html;
    const node = placeholder.firstElementChild;

    if (!document.body.classList.contains('loading-active')) {
      document.body.classList.add('loading-active');
      document.body.insertBefore(node, document.body.firstChild);
    } else {
      document.body.querySelector('.loading p').innerHTML = message;
    }
  }

  hideLoading(): void {
    setTimeout(() => {
      document.body.classList.remove('loading-active');
      if(document.body.querySelector('.loading')){
        document.body.querySelector('.loading').remove();
      }
    }, 0);
  }

  openSnackBar(message: string, action: string, css: string): void {
    this.snackBar.open(message, action, {
      duration: 3500,
      panelClass: css
    });
  }

  showMessage(message: any): void {
    this.openSnackBar(message, 'cerrar', 'snack-ok');
  }

  showError(message: any): void {
    this.openSnackBar(message, 'cerrar', 'snack-error');
  }

  handleError(data: any, overlayLoading: boolean = true): void {
    if(overlayLoading){
      this.hideLoading();
    }
    if (data.status === 400) {
      this.showError(data.error ? data.error.message : 'Algo hiciste mal');
    } else if (data.status === 401) {
      localStorage.removeItem('token');
      /*if (data.error) {
        this.showError(data.error.message);
      }*/
      this.openModal({width: '50%', panelClass: 'modal-unauthorized'}, NotAuthorizedComponent, (response: any) => {
        if (response) {
          this.router.navigateByUrl('/login', { replaceUrl: true });
        }
      });
    } else if (data.status === 404) {
      this.showError(data.error ? data.error.message : 'Recurso no encontrado.');
    }else if(data.status === 0){
      this.showError(data.message);
    } else {
      this.showError(data.error ? data.error.message : 'Ocurrió un error inesperado');
    }
  }

  getMessage(data: any): any {
    return data.body.message;
  }


  is200(data: any): boolean {
    return data.status === 200;
  }

  is201(data: any): boolean {
    return data.status === 201;
  }

  is204(data: any): boolean {
    return data.status === 204;
  }

  isSuccess(data: any) {
    return this.is200(data) && data.body.status === 'SUCCESS';
  }

  openModal(data: any, modal: any, response: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = data.width || '600px';
    dialogConfig.panelClass = data.panelClass || null;
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(modal, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      response(result);
    });
  }

  openBottomSheet(data: any, modal: any, response: any): void {
    const bottomSheetRef = this.bottomSheet.open(modal, { data });
    bottomSheetRef.afterDismissed().subscribe((result) => {
      response(result);
    });
  }

  // metodo para verificar los permisos
  execute(alias: string, result: any, error: any, config: boolean = false): void {
    this.showLoading('Verificando acceso...');
    this.api.getVerify(alias.substring(1), config).subscribe((data: any) => {
      if (this.isSuccess(data)) {
        this.hideLoading();
        result(config ? data.body.data : null);
      }
    }, (err: any) => {
      //this.hideLoading();
      this.handleError(err, true);
      error();
    });
  }

  hideSiderbar(): void{
    this.sidebarListener$.next(true);
  }

  showAlert(title: string, btn: string, fn: any): void{
    Swal.fire({
      position: 'bottom-end',
      icon: 'success',
      title: title,
      timer: 1115500,
      confirmButtonText: btn,
      backdrop: false,
      showCloseButton: true
    }).then((result) => {
      fn(result);
    })
  }

  toastSuccess(title: string, message: string): void{
    this.toastr.success(message, title, {
      positionClass: 'toast-top-right'
    });
  }

  toastError(title: string, message: string): void{
    this.toastr.error(message, title, {
      positionClass: 'toast-top-right'
    });
  }

}
