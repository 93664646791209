
<div class="row" style="margin: 50px 0;">
    <div class="col-md-12 text-center" *ngIf="big === 1">
        <mat-spinner mode="indeterminate" diameter="30" style="margin:0 auto;"></mat-spinner>
        <h3>
            {{msg}}
        </h3>
    </div>

    <div class="col-md-12 text-center" *ngIf="big === 2">
        <mat-spinner mode="indeterminate" diameter="30" style="margin:0 auto;"></mat-spinner>
        <h4>
            {{msg}}
        </h4>
    </div>

    <div class="col-md-12 text-center" *ngIf="big === 3">
        <mat-spinner mode="indeterminate" diameter="25" style="margin:0 auto;"></mat-spinner>
        <p class="fs-0-75">
            {{msg}}
        </p>
    </div>
</div>