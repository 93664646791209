import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ui-filter-customer-solution',
  templateUrl: './ui-filter-customer-solution.component.html',
  styleUrls: ['./ui-filter-customer-solution.component.scss']
})
export class UiFilterCustomerSolutionComponent implements OnInit {

  @Input() form: FormGroup = null;

  @Output() onSolutionSelected = new EventEmitter();

  loading: any = {
    customers: false,
    establishments: false,
    solutions: false
  };

  customers: any = [];

  establishments: any = [];

  solutions: any = [];

  description: any = environment.description;

  filteredOptions: Observable<any>;

  customerSelected: any = null;

  constructor(private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {
    this.listCustomers();

    this.filteredOptions = this.form.get('customer').valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.fullName;
        return name ? this._filter(name as string) : this.customers.slice();
      }),
    );

  }

  set frmCustomer(val) {
    this.form.get('customer').setValue(val);
  }
  get frmCustomer() {
    return this.form.get('customer').value;
  }

  set frmEstablishment(val) {
    this.form.get('establishment').setValue(val);
  }
  get frmEstablishment() {
    return this.form.get('establishment').value;
  }

  set frmSolution(val) {
    this.form.get('solution').setValue(val);
  }
  get frmSolution() {
    return this.form.get('solution').value;
  }

  generateChoice(): any{
    return {
      id: -1,
      name: 'Seleccione'
    };
  }

  listCustomers(): void {
    this.loading.customers = true;
    this.api.getCustomersForSelect().subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.customers = false;
        const body = data.body.data;
        if(body.all){
          this.customers = body.list;
        }else{
          this.customers = body.list;
          this.form.get('customer').disable();
        }
        this.frmCustomer = body.all ? -1 : this.customers[0].id;
        if(this.frmCustomer !== -1){
          this.listEstablishments(this.frmCustomer);
        }
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onChangeCustomer(event: any): void {
    if (event.value !== -1) {
      this.listEstablishments(event.value);
    } else {
      this.frmEstablishment = null;
      this.frmSolution = null;
      this.establishments = [];
      this.solutions = [];
    }
  }

  listEstablishments(id: number): void {
    this.loading.establishments = true;
    this.api.getEstablishmentsForSelect(id).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.establishments = false;
        const body = data.body.data;
        if(body.all){
          this.establishments = [this.generateChoice()];
          this.establishments = this.establishments.concat(body.list);
        }else{
          this.establishments = body.list;
          this.form.get('establishment').disable();
        }
        this.frmEstablishment = this.establishments[0].id;
        if(!body.all && this.frmEstablishment !== -1){
          this.listSolutions(this.frmEstablishment);
        }
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });

  }

  onChangeEstablishment(event: any): void {
    if (event.value !== -1) {
      this.listSolutions(event.value);
    } else {
      this.frmSolution = null;
      this.solutions = [];
    }
  }

  listSolutions(id: number): void {
    this.loading.solutions = true;
    this.api.getSolutionsForSelect(id).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.solutions = false;
        const body = data.body.data;
        this.solutions = [this.generateChoice()];
        this.solutions = this.solutions.concat(body.list);
        this.frmSolution = this.solutions[0].id;
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onChangeSolution(event: any): void {
    this.onSolutionSelected.emit(event.value);
  }

  displayFn(row: any): string {
    return row && row.fullName ? row.fullName : '';
  }

  private _filter(name: string): any {
    const filterValue = name.toLowerCase();
    return this.customers.filter((option: any) => option.fullName.toLowerCase().includes(filterValue));
  }

  optionSelected(event: any): void {
    this.customerSelected = this.frmCustomer;
    this.onSelectCustomer();
  }

  onChange(event: any): void {
    this.customerSelected = null;
    this.onSelectCustomer();
  }

  onSelectCustomer(): void {
    if (this.customerSelected) {
      this.listEstablishments(this.customerSelected.id);
    } else {
      this.frmEstablishment = null;
      this.frmSolution = null;
      this.establishments = [];
      this.solutions = [];
    }
  }

}
